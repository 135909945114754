<template functional>
	<article class="component c-story">
		<h2
			class="c-story__title margin-t-0 margin-b-12 padding-x-16 font-size-18 line-height-175
				bp-768:font-size-30 bp-768:line-height-150 bp-768:padding-x-24
				bp-1280:font-size-36"
		>
			{{ props.story.title }}
		</h2>
		<div
			v-if="props.story.subtitle"
			class="c-story__subtitle margin-b-12 text-muted padding-x-16
				bp-768:font-size-18 bp-768:margin-b-24 bp-768:padding-x-24
				bp-1280:line-height-175"
			v-html="props.story.subtitle"
		></div>
		<div
			class="c-story__byline margin-b-12 font-style-italic font-weight-500 padding-x-16
				bp-768:margin-b-24 bp-768:padding-x-24"
		>
			<span class="c-story__date">
				{{ new Date(props.story.pubDate).toISOString() | luxon }}
			</span>
			<span class="c-story__author" v-if="props.story.byline"
				>| {{ props.story.byline }}</span
			>
		</div>
		<div
			v-if="props.story.image"
			class="c-story__media bg-color-surface-alt aspect-ratio-16-9 position-relative"
			:class="{
				' margin-b-24': !props.story.image_caption,
			}"
		>
			<img
				class="c-story__media-image display-block utility-position-cover object-fit-cover"
				v-lazy="
					`${parent.$root.proxy}/common/controls/image_handler.aspx?thumb_id=0&image_path=${props.story.image}`
				"
				:alt="props.story.image_alt_text"
			/>
		</div>
		<div
			v-if="props.story.image_caption"
			class="c-story__subtitle font-size-12 bg-color-surface-alt padding-12"
			v-html="props.story.image_caption"
		></div>
		<div
			class="c-story__content clear-both
			bp-768:padding-x-24"
			v-html="props.story.fulltext"
		></div>
	</article>
</template>

<script>
export default {
	name: 'Story',

	props: {
		story: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.c-story {
	&__title {
		box-shadow: inset 6px 0 0 var(--color-primary);
		font-family: mislab-std, sans-serif;
	}
}
</style>
