import { render, staticRenderFns } from "./PanelHero.vue?vue&type=template&id=1994907f&scoped=true&functional=true&"
import script from "./PanelHero.vue?vue&type=script&lang=js&"
export * from "./PanelHero.vue?vue&type=script&lang=js&"
import style0 from "./PanelHero.vue?vue&type=style&index=0&id=1994907f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "1994907f",
  null
  
)

export default component.exports