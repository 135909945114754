var render = function (_h,_vm) {var _c=_vm._c;return (_vm.props.data || _vm.props.panels)?_c('div',{staticClass:"page-template page-template-4"},[_c('div',{staticClass:"row display-block max-width-grid padding-x-12\n\t\t\tbp-1024:padding-x-24"},[_c('div',{staticClass:"row",class:{
				'margin-b-12 bp-1024:margin-b-24': _vm.props.panels.hero.length,
			}},[_c('div',{staticClass:"col-1-of-1 panel-section-hero"},_vm._l((_vm.props.panels.hero),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-1-of-1"},[_c('h2',{staticClass:"page-template__title margin-t-0 margin-b-12 padding-x-16 font-size-18 line-height-175\n\t\t\t\t\t\tbp-768:font-size-30 bp-768:line-height-150 bp-768:padding-x-24\n\t\t\t\t\t\tbp-1280:font-size-36",class:{ 'accessible-hide': !_vm.props.data.displayTitle }},[_vm._v(" "+_vm._s(_vm.props.data.title)+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.props.data.fulltext_raw)}}),_vm._l((_vm.props.panels.main_1),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),_vm._l((_vm.props.panels.main_2),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})})],2)]),_c('div',{staticClass:"row",class:{
				'margin-t-12 bp-1024:margin-t-24':
					_vm.props.panels.main_3.length ||
					_vm.props.panels.main_4.length,
			}},[_c('div',{staticClass:"col-1-of-1 bp-1024:col-2-of-3 panel-section-3 no-print"},_vm._l((_vm.props.panels.main_3),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1),_c('div',{staticClass:"col-1-of-1 bp-1024:col-1-of-3 panel-section-4"},_vm._l((_vm.props.panels.main_4),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1)]),_c('div',{staticClass:"row",class:{
				'margin-t-12 bp-1024:margin-t-24':
					_vm.props.panels.main_5.length ||
					_vm.props.panels.main_6.length,
			}},[_c('div',{staticClass:"col-1-of-1 bp-1024:col-1-of-3 panel-section-5"},_vm._l((_vm.props.panels.main_5),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1),_c('div',{staticClass:"col-1-of-1 bp-1024:col-2-of-3 panel-section-6"},_vm._l((_vm.props.panels.main_6),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1)]),_c('div',{staticClass:"row",class:{
				'margin-t-12 bp-1024:margin-t-24':
					_vm.props.panels.main_7.length,
			}},[_c('div',{staticClass:"col-1-of-1 panel-section-7"},_vm._l((_vm.props.panels.main_7),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1)]),(_vm.props.data.taxonomy.length)?_c('div',{staticClass:"border-t border-style-dashed margin-t-12 padding-y-24\n\t\t\t\t\tbp-768:padding-x-24 bp-1024:margin-t-24"},[_c(_vm.injections.components.Tags,{tag:"component",attrs:{"tags":_vm.props.data.taxonomy}})],1):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }