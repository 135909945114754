<template>
	<div>
		<loading-indicator vertical-space="500" v-if="loading" />
		<transition name="fade">
			<div v-if="!loading">
				<component :is="type" :content="content" />
			</div>
		</transition>
	</div>
</template>

<script>
import { get } from '@/helpers';
import Page from '@/views/Page';
import Taxonomy from '@/views/Taxonomy';
import Story from '@/views/stories/Story';
import LoadingIndicator from '@/components/Common/LoadingIndicator';

export default {
	name: 'Operator',

	components: {
		LoadingIndicator,
		Page,
		Taxonomy,
		Story,
	},

	data: () => ({
		content: null,
		loading: false,
		type: null,
	}),

	methods: {
		get_content(slug) {
			this.loading = true;

			var token = this.$route.query.token;

			let request_options = {
				slug: slug,
				token: token ?? ""
			};

			get(
				`${this.$root.proxy}/services/content.ashx/get`,
				request_options,
			)
				.then(response => {
					this.content = response.content;
					switch (response.content_type) {
						case 'page':
							this.type = 'Page';
							break;
						case 'tag':
							this.type = 'Taxonomy';
							break;
						case 'school':
							this.type = 'Taxonomy';
							break;
						case 'story':
							this.type = 'Story';
							break;
						default:
							this.$router.push({ name: 'Missing' });
							break;
					}
					this.loading = false;
				})
				.catch(e => {
					this.loading = false;
					this.$router.push({ name: 'Missing' });
				});
		},
	},

	created() {
		this.get_content(this.$route.path);
	},

	watch: {
		$route() {
			this.get_content(this.$route.path);
		},
	},
};
</script>
