<template functional>
	<div
		class="component c-contact-card text-align-center
		bp-768:text-align-left"
		v-if="props.contact"
	>
		<div
			class="c-contact-card__link display-block align-items-center text-decoration-none"
		>
			<div
				class="flex-grow-1 margin-b-12
					bp-768:margin-b-0"
			>
				<div class="font-weight-500 font-size-18">
					{{ props.contact.first_name }}
					{{ props.contact.last_name }}
				</div>
				<div class="font-size-14 text-muted">
					{{ props.contact.title }}
				</div>
				<div class="align-items-center">
					<a
						class="font-size-14 text-brand text-muted text-decoration-none
							hover:text-decoration-underline
						"
						:href="`mailto:${props.contact.email_address}`"
						target="_blank"
					>
						{{ props.contact.email_address }}
					</a>
					<span
						v-if="
							props.contact.telephone &&
								props.contact.email_address
						"
						class="font-size-14 margin-x-4 text-muted pointer-events-none"
						aria-hidden="true"
						>|</span
					>
					<a
						class="font-size-14 text-brand text-muted text-decoration-none
							hover:text-decoration-underline
						"
						:href="`tel:${props.contact.telephone}`"
					>
						{{ props.contact.telephone }}
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ContactCard',

	props: {
		contact: {
			type: Object,
			required: true,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
.c-contact-card {
	&__image {
		border: 3px solid var(--color-default-theme-surface);
	}
	&__link {
		&:hover,
		&:focus {
			.feather {
				transform: translateX(6px);
			}
		}
	}
}
</style>
