<template functional>
	<div
		class="story-template-1 padding-t-24
			bp-768:padding-b-24"
	>
		<div
			class="row max-width-grid padding-x-12
			bp-768:padding-x-24
		"
		>
			<div
				class="col-1-of-1 padding-r-12
					bp-1024:padding-r-24
					bp-1024:col-2-of-3"
			>
				<component
					:is="injections.components.StoryContent"
					:story="props.story"
				/>
				<div
					class="border-t margin-t-24 padding-x-12 padding-y-24 border-style-dashed
						bp-768:margin-t-48 bp-768:padding-x-0"
					v-if="props.story.mediaContact"
				>
					<div class="bp-768:padding-x-24">
						<div
							class="font-weight-700 font-size-18 text-align-center margin-b-12
								bp-768:text-align-left bp-768:margin-b-24"
						>
							Media Contact
						</div>
						<component
							:contact="props.story.mediaContact"
							:is="injections.components.ContactCard"
						/>
					</div>
				</div>
				<div
					class="border-t border-style-dashed padding-y-24
						bp-768:padding-x-24"
				>
					<component
						:tags="props.story.taxonomy"
						v-if="props.story.taxonomy.length"
						:is="injections.components.Tags"
					/>
				</div>
			</div>
			<div class="col-1-of-1 bp-1024:col-1-of-3 no-print">
				<div
					class="margin-t-24 padding-x-12 padding-y-24 bg-color-surface border
						bp-768:padding-24
						bp-1024:position-sticky
						story-template-1__sticky
					"
				>
					<div
						class="font-weight-700 font-size-18 padding-b-12 border-b text-brand"
					>
						Recent News
					</div>
					<component
						template="StoriesList"
						section-title="Related News"
						:count="5"
						:is="injections.components.Stories"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContactCard from '@/components/Common/ContactCard';
import Stories from '@/components/stories/Stories';
import StoryContent from '@/components/Common/StoryContent';
import Tags from '@/components/Common/Tags';


export default {
	name: 'StoryTemplate1',

	inject: {
		components: {
			default: {
				ContactCard,
				Stories,
				StoryContent,
				Tags,
			},
		},
	},

	props: {
		story: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.story-template-1 {
	@media screen and (min-width: 1024px) {
		&__sticky {
			top: var(--full-header-height);
		}
	}
}
</style>
