<template functional>
	<div class="page-template page-template-1" v-if="props.data">
		<div class="row display-block max-width-grid">
			<h2
				class="page-template__title margin-t-0 margin-b-12 padding-x-16 font-size-18 line-height-175
				bp-768:font-size-30 bp-768:line-height-150 bp-768:padding-x-24
				bp-1280:font-size-36"
				:class="{ 'accessible-hide': !props.data.displayTitle }"
			>
				{{ props.data.title }}
			</h2>
			<div class="row">
				<div class="col" v-html="props.data.fulltext_raw"></div>
			</div>
			<div
				class="border-t border-style-dashed padding-y-24
						bp-768:padding-x-24"
				v-if="props.data.taxonomy.length"
			>
				<component
					:is="injections.components.Tags"
					:tags="props.data.taxonomy"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Tags from '@/components/Common/Tags';

export default {
	name: 'PageTemplate1',

	inject: {
		components: {
			default: {
				Tags,
			},
		},
	},

	props: {
		data: {
			required: true,
			type: Object,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
.page-template {
	&__title {
		box-shadow: inset 6px 0 0 var(--color-primary);
	}
}

[class*='panel-section-'] + [class*='panel-section-'] {
	@media (max-width: 1024px) {
		margin-top: 12px;
	}
}
</style>
