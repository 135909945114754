<template functional>
	<div
		class="component c-tags display-flex font-size-12 font-weight-500"
		v-if="props.tags.length"
	>
		<div class="c-tags__title margin-r-6">Tags:</div>
		<ul class="c-tags__list reset-list display-flex flex-wrap-wrap">
			<template v-for="(tag, index) in props.tags">
				<li class="c-tags__list-item" :key="index" v-if="tag.slug">
					<router-link
						:to="tag.slug"
						class="text-decoration-none
							hover:text-decoration-underline"
					>
						{{ tag.name }}
					</router-link>
				</li>
				<span
					v-if="index < props.tags.length - 1 && tag.slug"
					:key="`dot_${index}`"
					class="margin-x-4 text-muted pointer-events-none"
					aria-hidden="true"
				>
					&bull;
				</span>
			</template>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'Tags',
	props: {
		tags: {
			type: Array,
			required: true,
			default: () => [],
		},
	},
};
</script>
