<template functional>
	<div
		class="page-template page-template-2"
		v-if="props.data || props.panels"
	>
		<div
			class="row display-block max-width-grid padding-x-12 width-100
				bp-1024:padding-x-24"
		>
			<!-- LAYOUT content_detail /about/division-iii-week-2021 -->

			<div
				class="row"
				:class="{
					'margin-b-12 bp-1024:margin-b-24':
						props.panels.main_1.length,
				}"
			>
				<div class="col-1-of-1 panel-section-1">
					<component
						:is="injections.components.Panel"
						:panel-data="panel"
						v-for="(panel, index) in props.panels.main_1"
						:key="index"
						:_class="
							index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''
						"
					/>
				</div>
			</div>

			<div class="row max-width-grid">
				<div
					:class="{
						'col-1-of-1 bp-1024:col-2-of-3 padding-r-12 bp-1024:padding-r-24':
							props.panels,
					}"
				>
					<h2
						class="page-template__title margin-t-0 margin-b-12 padding-x-16 font-size-18 line-height-175
						bp-768:font-size-30 bp-768:line-height-150 bp-768:padding-x-24
						bp-1280:font-size-36"
						:class="{ 'accessible-hide': !props.data.displayTitle }"
					>
						{{ props.data.title }}
					</h2>
					<div v-html="props.data.fulltext_raw"></div>
				</div>
				<div
					class="page-template__panels --main-2 col-1-of-1 padding-t-12 panel-section-2
					bp-1024:col-1-of-3 bp-1024:padding-t-0 bp-1024:position-sticky no-print"
					v-if="props.panels"
				>
					<component
						:is="injections.components.Panel"
						:panel-data="panel"
						v-for="(panel, index) in props.panels.main_2"
						:key="index"
						:_class="
							index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''
						"
					/>
				</div>
			</div>
			<div
				class="border-t border-style-dashed padding-y-24 margin-t-12 col-1-of-1
						bp-768:padding-x-24 bp-1024:margin-t-24"
				v-if="props.data.taxonomy.length"
			>
				<component
					:is="injections.components.Tags"
					:tags="props.data.taxonomy"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Tags from '@/components/Common/Tags';

import Panel from '@/components/page_panels/Panel';

export default {
	name: 'PageTemplate2',

	inject: {
		components: {
			default: {
				Tags,
				Panel,
			},
		},
	},

	props: {
		data: {
			required: true,
			type: Object,
			default: null,
		},
		panels: {
			type: Object,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
.page-template {
	&__title {
		box-shadow: inset 6px 0 0 var(--color-primary);
	}

	@media screen and (min-width: 1024px) {
		&__panels {
			top: 80px;
		}
	}
}

[class*='panel-section-'] + [class*='panel-section-'] {
	@media (max-width: 1024px) {
		margin-top: 12px;
	}
}
</style>
