<template functional>
	<div class="c-panel--generic bg-color-surface border padding-24">
		<h3 v-if="props.panelTitle != ''" class="font-weight-700 font-size-18">
			{{ props.panelTitle }}
		</h3>
		<div v-if="props.content != ''" v-html="props.content"></div>
		<component
			v-if="props.linkText && props.linkUrl"
			:is="injections.components.NavigationLink"
			:url="props.linkUrl"
		>
			{{ props.linkText }}
		</component>
	</div>
</template>

<script>
import NavigationLink from '@/components/Common/NavigationLink';

export default {
	name: 'PanelGeneric',

	props: {
		content: {
			default: '',
			type: String,
		},
		panelTitle: {
			default: '',
			type: String,
		},
		linkText: {
			default: '',
			type: String,
		},
		linkUrl: {
			default: '',
			type: String,
		},
	},

	inject: {
		components: {
			default: {
				NavigationLink,
			},
		},
	},
};
</script>

<style lang="scss">
figure.image {
	margin: 12px 0;
}
</style>
