<template>
	<main class="sa-view-stories-archives">
		<div class="padding-x-24 padding-y-48">
			<div class="row max-width-grid display-block">
				<div
					class="section-header margin-b-24 bp-768:margin-b-48"
					v-if="content.title"
				>
					<h2 class="section-header__title margin-0">
						{{ content.title }}
					</h2>
					<div 
						class="line-height-150 margin-t-16"
						v-if="content.description && content.description != ''"
					>
						{{ content.description }}
					</div>
				</div>
				<div
					class="c-taxonomy__filters-container row margin-b-12 bp-1024:margin-b-24"
				>
					<div
						class="c-taxonomy__select-filter margin-b-12 col-1-of-1 bp-1024:col-1-of-3 bp-1024:padding-r-24 bp-1024:margin-b-0"
					>
						<div class="position-relative height-100">
							<select
								class="c-taxonomy__select border text-brand font-size-14 font-weight-bold padding-x-24 reset width-100 text-transform-capitalize"
								v-model="selected_content_type"
								@change="filter_by_content_type"
							>
								<option value="all">All Content Types</option>
								<option
									class="c-taxonomy__select-option"
									v-for="(content_type,
									index) in content_types"
									:key="index"
									:value="content_type"
									>{{ content_type }}</option
								>
							</select>
							<div
								class="c-taxonomy__select-icon border-l height-100 padding-x-24 position-absolute top-50 right-0 transform-translateY-n50"
							>
								<filter-icon
									class="height-100 text-brand"
									size="20"
								></filter-icon>
							</div>
						</div>
					</div>
					<div
						class="c-taxonomy__search border col-1-of-1 bp-1024:col-2-of-3"
					>
						<input
							placeholder="Search by keyword..."
							type="text"
							class="c-archives__search-input font-size-14 font-weight-bold padding-x-24 text-brand reset height-100 width-100"
							v-model="filter.search"
						/>
					</div>
				</div>
				<div v-if="filtered_data.length">
					<ul class="reset-list">
						<li
							class="c-taxonomy__list-item
								bp-768:display-flex
							"
							v-for="(item, index) in pageOfItems"
							:key="item.story_id"
							:class="[
								{ 'padding-t-0 padding-b-24': index === 0 },
								{ 'padding-y-24': index != 0 },
								{
									'border-b border-style-dashed':
										index != filtered_data.length - 1,
								},
							]"
						>
							<div class="c-taxonomy__media-container">
								<div
									class="c-taxonomy__media aspect-ratio-16-9 position-relative overflow-hidden"
								>
									<img
										class="utility-position-center object-fit-cover object-position-c"
										src="@/assets/default.jpg"
									/>
								</div>
							</div>
							<div
								class="c-taxonomy__details padding-t-24 text-transform-capitalize
								bp-768:padding-24"
							>
								<h3
									class="c-taxonomy__title font-size-18 font-weight-700 margin-t-0 margin-b-12
										bp-1024:font-size-24
									"
								>
									{{ item.story_headline }}
								</h3>
								<div
									class="c-taxonomy__meta display-flex align-items-center margin-b-12"
								>
									<div
										class="c-taxonomy__meta-date"
										v-luxon="item.story_postdate"
									></div>
									<div
										class="c-taxonomy__meta-separator margin-x-6"
										v-if="
											item.content_type &&
												item.story_postdate
										"
									>
										|
									</div>
									<div
										class="c-taxonomy__meta-content-type text-transform-capitalize"
									>
										{{ item.content_type }}
									</div>
								</div>
								<div
									class="c-taxonomy__summary margin-b-12 display-none
										bp-768:display-block
									"
									v-html="item.story_summary"
								></div>
								<navigation-link
									_class="display-flex align-items-center font-weight-700 text-decoration-none hover:text-decoration-underline"
									:url="item.story_path"
								>
									Read Here
									<arrow-right-circle-icon
										class="font-weight-400 margin-l-12 text-brand transition"
									></arrow-right-circle-icon>
								</navigation-link>
							</div>
						</li>
					</ul>
				</div>
				<div v-else>
					There is currently no content for
					<span class="font-weight-500">{{ page_title }}</span> that
					matches the active filters.
				</div>
				<jw-pagination
					class=" margin-t-12 width-100"
					:page-size="5"
					:items="filtered_data"
					@changePage="onChangePage"
				></jw-pagination>
			</div>
		</div>
	</main>
</template>

<script>
import { get_unique_values_of_property, sort_by } from '@/helpers';
import { ArrowRightCircleIcon, FilterIcon } from 'vue-feather-icons';
import NavigationLink from '@/components/Common/NavigationLink';

export default {
	name: 'Taxonomy',

	components: {
		NavigationLink,
		FilterIcon,
		ArrowRightCircleIcon,
	},

	props: {
		content: {
			type: Object,
			default: () => {},
		},
	},

	data: () => ({
		selected_content_type: 'all',
		filter: {
			content_type: 'all',
			search: '',
		},
		pageOfItems: [],
	}),

	computed: {
		content_types() {
			return get_unique_values_of_property(
				this.content.items,
				'content_type',
			).sort();
		},
		filtered_data() {
			let _data = this.content.items.filter(d => {
				for (const key in this.filter) {
					if (key === 'search') {
						let search = this.filter.search.toLowerCase();
						let title = d.story_headline.toLowerCase();
						if (search === '') continue;
						if (title.indexOf(search) === -1) return false;
						else continue;
					} else if (this.filter[key] === 'all') {
						continue;
					} else if (d[key] !== this.filter[key]) {
						return false;
					} else {
						continue;
					}
				}
				return true;
			});

			// Only return unique items by story_id
			_data = [
				...new Map(
					_data.map(item => [item['story_id'], item]),
				).values(),
			];

			return _data.sort(sort_by('story_postdate')).reverse();
		},
	},

	methods: {
		filter_by_content_type() {
			this.filter.content_type = this.selected_content_type;
		},
		onChangePage(pageOfItems) {
			this.pageOfItems = pageOfItems;
		},
	},

	created() {
		this.setMetaSidearm(this.content.title, [
			{
				name: 'og:title',
				content: this.content.title,
			},
			{
				name: 'twitter:title',
				content: this.content.title,
			},
		]);
	},
};
</script>

<style lang="scss" scoped>
.c-taxonomy {
	//for naming
	&__select {
		height: 65px;
		&:hover,
		&:focus {
			cursor: pointer;
		}
		&-icon {
			pointer-events: none;
		}
	}

	&__search {
		height: 65px;
	}

	&__list-item {
		transition: all 0.5s;
	}
	&__media-container {
		flex: 0 0 33.3333%;
	}
	&__details {
		flex: 0 1 66.6666%;
	}
	.list-enter,
	.list-leave-to {
		opacity: 0;
		transform: translateY(30px);
	}
	.list-leave-active {
		position: absolute;
	}
}
</style>
