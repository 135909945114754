<template functional>
	<div
		class="c-panel--hero position-relative height-auto overflow-hidden text-center"
		v-if="props.heroImage && props.heroImage != ''"
	>
		<img
			class="object-fit-cover object-position-t utility-position-cover"
			:src="parent.$root.proxy + props.heroImage + '?width=1920&height=509&mode=crop&anchor=topcenter&format=jpg&quality=80'"
			:alt="props.panelTitle != '' ? props.panelTitle : ''"
		/>
	</div>
</template>

<script>
export default {
	name: 'PanelHero',

	props: {
		heroImage: {
			type: String,
			default: '',
		},
		panelTitle: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
.c-panel--hero {
	padding-bottom: 26.5%;
}
</style>
