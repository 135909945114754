<template>
	<div
		class="page padding-t-24
			bp-768:padding-b-24"
	>
		<div v-if="content">
			<component :is="template" :data="content" :panels="panels" />
		</div>
	</div>
</template>

<script>
import PageTemplate1 from '@/components/page_templates/PageTemplate1';
import PageTemplate2 from '@/components/page_templates/PageTemplate2';
import PageTemplate3 from '@/components/page_templates/PageTemplate3';
import PageTemplate4 from '@/components/page_templates/PageTemplate4';

export default {
	name: 'Page',

	components: {
		PageTemplate1,
		PageTemplate2,
		PageTemplate3,
		PageTemplate4,
	},

	props: {
		content: {
			type: Object,
			default: () => {},
		},
	},

	computed: {
		layout() {
			if (this.content && this.content.content_layout)
				return this.content.content_layout;
			return 'none';
		},

		template() {
			if (this.layout === 'ncaa_content_detail') {
				return 'PageTemplate2';
			} else if (this.layout === 'ncaa_1231321') {
				return 'PageTemplate3';
			} else if (this.layout === 'ncaa_cat2') {
				return 'PageTemplate4';
			} else {
				return 'PageTemplate1';
			}
		},

		panels() {
			if (this.layout === 'ncaa_content_detail') {
				return this.filter_panels(2);
			} else if (this.layout === 'ncaa_1231321') {
				return this.filter_panels(13);
			} else if (this.layout === 'ncaa_cat2') {
				let _panels = {};
				_panels.hero = this.content.panels.filter(
					p => p.section === 'hero',
				);
				return { ..._panels, ...this.filter_panels(7) };
			} else {
				return {};
			}
		},
	},

	methods: {
		filter_panels(count) {
			let _panels = {};
			for (let i = 1; i <= count; i++) {
				_panels[`main_${i}`] = this.content.panels.filter(
					p => p.section === `main_${i}`,
				);
			}
			return _panels;
		},
	},

	created() {
		this.setMetaSidearm(this.content.title, [
			{
				name: 'description',
				content: this.content.summary ? this.content.summary : '',
			},
			{
				name: 'og:title',
				content: this.content.title,
			},
			{
				name: 'og:description',
				content: this.content.summary ? this.content.summary : '',
			},
			{
				name: 'twitter:title',
				content: this.content.title,
			},
			{
				name: 'twitter:description',
				content: this.content.summary ? this.content.summary : '',
			},
		]);
	},
};
</script>

<style lang="scss">
figure.image {
	text-align: center;
}
figure.image.image-style-align-left {
	text-align: left;
}
figure.image.image-style-align-right {
	text-align: right;
}
</style>
