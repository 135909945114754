<template>
	<div>
		<component :is="story_template" :story="content" v-if="content" />
	</div>
</template>

<script>
import StoryTemplate1 from '@/components/story_templates/StoryTemplate1';
import StoryTemplate2 from '@/components/story_templates/StoryTemplate2';

export default {
	name: 'Story',

	components: {
		StoryTemplate1,
		StoryTemplate2,
	},

	props: {
		content: {
			type: Object,
			default: () => {},
		},
	},

	computed: {
		story_template: function() {
			if (this.content.template) {
				let template_string = this.content.template.split('_')[1];
				return `StoryTemplate${template_string}`;
			}
			return 'StoryTemplate1';
		},
	},

	created() {
		this.setMetaSidearm(this.content.title, [
			{
				name: 'description',
				content: this.content.summary ? this.content.summary : '',
			},
			{
				name: 'og:title',
				content: this.content.title,
			},
			{
				name: 'og:description',
				content: this.content.summary ? this.content.summary : '',
			},
			{
				name: 'twitter:title',
				content: this.content.title,
			},
			{
				name: 'twitter:description',
				content: this.content.summary ? this.content.summary : '',
			},
		]);
	},
};
</script>
