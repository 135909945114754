<template functional>
	<div class="c-panel--link-list">
		<div class="bg-color-surface border padding-24">
			<h3 v-if="props.panelTitle" class="font-weight-700 font-size-18">
				{{ props.panelTitle }}
			</h3>
			<ul
				class="reset-list panel-list"
				v-if="props.links && props.links.length"
			>
				<li v-for="(link, index) in props.links" :key="index">
					<component
						v-if="link.url && link.text"
						:is="injections.components.NavigationLink"
						:url="link.url"
					>
						{{ link.text }}
					</component>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import NavigationLink from '@/components/Common/NavigationLink';

export default {
	name: 'PanelLinkList',

	props: {
		panelTitle: {
			type: String,
			default: '',
		},
		links: {
			type: Array,
			default: () => [],
		},
	},

	inject: {
		components: {
			default: {
				NavigationLink,
			},
		},
	},
};
</script>
