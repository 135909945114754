<template functional>
	<div class="c-panel" :class="props._class">
		<template v-if="props.panelData.type === 'generic_block'">
			<component
				:is="injections.components.PanelGeneric"
				:panel-title="props.panelData.title"
				:content="props.panelData.content"
				:link-text="props.panelData.link_text"
				:link-url="props.panelData.link_url"
			/>
		</template>
		<template v-if="props.panelData.type === 'link_list'">
			<component
				:is="injections.components.PanelLinkList"
				:panel-title="props.panelData.title"
				:links="props.panelData.links"
			/>
		</template>
		<template v-if="props.panelData.type === 'hero'">
			<component
				:is="injections.components.PanelHero"
				:panel-title="props.panelData.title"
				:hero-image="props.panelData.hero_image_url"
			/>
		</template>
	</div>
</template>

<script>
import PanelGeneric from '@/components/page_panels/PanelGeneric';
import PanelHero from '@/components/page_panels/PanelHero';
import PanelLinkList from '@/components/page_panels/PanelLinkList';

export default {
	name: 'Panel',

	props: {
		_class: {
			type: String,
		},
		panelData: {
			type: Object,
			required: true,
		},
	},

	inject: {
		components: {
			default: {
				PanelGeneric,
				PanelHero,
				PanelLinkList,
			},
		},
	},
};
</script>
