var render = function (_h,_vm) {var _c=_vm._c;return (_vm.props.data || _vm.props.panels)?_c('div',{staticClass:"page-template page-template-3"},[_c('div',{staticClass:"row display-block max-width-grid padding-x-12 width-100\n\t\t\tbp-1024:padding-x-24"},[_c('div',{staticClass:"row",class:{
				'margin-b-12 bp-1024:margin-b-24':
					_vm.props.panels.main_1.length,
			}},[_c('div',{staticClass:"col-1-of-1 panel-section-1"},_vm._l((_vm.props.panels.main_1),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1)]),_c('div',{staticClass:"row",class:{
				'margin-b-12 bp-1024:margin-b-24':
					_vm.props.panels.main_2.length,
			}},[_c('div',{staticClass:"col-1-of-1 panel-section-2"},_vm._l((_vm.props.panels.main_2),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-1-of-1"},[_c('h2',{staticClass:"page-template__title margin-t-0 margin-b-12 padding-x-16 font-size-18 line-height-175\n\t\t\t\t\t\tbp-768:font-size-30 bp-768:line-height-150 bp-768:padding-x-24\n\t\t\t\t\t\tbp-1280:font-size-36",class:{ 'accessible-hide': !_vm.props.data.displayTitle }},[_vm._v(" "+_vm._s(_vm.props.data.title)+" ")])])]),_c('div',{staticClass:"row",class:{
				'margin-t-12 bp-1024:margin-t-24':
					_vm.props.data.fulltext || _vm.props.panels.main_3.length,
			}},[_c('div',{staticClass:"col-1-of-1 panel-section-2",class:{
					'bp-1024:col-2-of-3 bp-1024:padding-r-12':
						_vm.props.panels.main_3.length,
				}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.props.data.fulltext_raw)}})]),(_vm.props.panels.main_3.length)?_c('div',{staticClass:"col-1-of-1 panel-section-3\n\t\t\t\tbp-1024:col-1-of-3 bp-1024:padding-l-12 no-print"},_vm._l((_vm.props.panels.main_3),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1):_vm._e()]),_c('div',{staticClass:"row",class:{
				'margin-t-12 bp-1024:margin-t-24':
					_vm.props.panels.main_4.length ||
					_vm.props.panels.main_5.length ||
					_vm.props.panels.main_6.length,
			}},[_c('div',{staticClass:"col-1-of-1 panel-section-4\n\t\t\t\tbp-1024:col-1-of-3 bp-1024:padding-r-12\n\t\t\t"},_vm._l((_vm.props.panels.main_4),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1),_c('div',{staticClass:"col-1-of-1 panel-section-5\n\t\t\t\tbp-1024:col-1-of-3 bp-1024:padding-x-12"},_vm._l((_vm.props.panels.main_5),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1),_c('div',{staticClass:"col-1-of-1 panel-section-6\n\t\t\t\tbp-1024:col-1-of-3 bp-1024:padding-l-12"},_vm._l((_vm.props.panels.main_6),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1)]),_c('div',{staticClass:"row",class:{
				'margin-t-12 bp-1024:margin-t-24':
					_vm.props.panels.main_7.length,
			}},[_c('div',{staticClass:"col-1-of-1 panel-section-7"},_vm._l((_vm.props.panels.main_7),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1)]),_c('div',{staticClass:"row",class:{
				'margin-t-12 bp-1024:margin-t-24':
					_vm.props.panels.main_8.length ||
					_vm.props.panels.main_9.length ||
					_vm.props.panels.main_10.length,
			}},[_c('div',{staticClass:"col-1-of-1 panel-section-8\n\t\t\t\tbp-1024:col-1-of-3 bp-1024:padding-r-12"},_vm._l((_vm.props.panels.main_8),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1),_c('div',{staticClass:"col-1-of-1 panel-section-9\n\t\t\t\tbp-1024:col-1-of-3 bp-1024:padding-x-12"},_vm._l((_vm.props.panels.main_9),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1),_c('div',{staticClass:"col-1-of-1 panel-section-10\n\t\t\t\tbp-1024:col-1-of-3 bp-1024:padding-l-12"},_vm._l((_vm.props.panels.main_10),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1)]),_c('div',{staticClass:"row",class:{
				'margin-t-12 bp-1024:margin-t-24':
					_vm.props.panels.main_11.length ||
					_vm.props.panels.main_12.length,
			}},[_c('div',{staticClass:"col-1-of-1 panel-section-11\n\t\t\t\tbp-1024:col-2-of-3 bp-1024:padding-r-12"},_vm._l((_vm.props.panels.main_11),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1),_c('div',{staticClass:"col-1-of-1 panel-section-12\n\t\t\t\tbp-1024:col-1-of-3 bp-1024:padding-l-12"},_vm._l((_vm.props.panels.main_12),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1)]),_c('div',{staticClass:"row",class:{
				'margin-t-12 bp-1024:margin-t-24':
					_vm.props.panels.main_13.length,
			}},[_c('div',{staticClass:"col-1-of-1 panel-section-13"},_vm._l((_vm.props.panels.main_13),function(panel,index){return _c(_vm.injections.components.Panel,{key:index,tag:"component",attrs:{"panel-data":panel,"_class":index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''}})}),1)]),(_vm.props.data.taxonomy.length)?_c('div',{staticClass:"border-t border-style-dashed margin-t-12 padding-y-24\n\t\t\t\t\tbp-768:padding-x-24 bp-1024:margin-t-24"},[_c(_vm.injections.components.Tags,{tag:"component",attrs:{"tags":_vm.props.data.taxonomy}})],1):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }