<template functional>
	<div
		class="story-template-1 padding-t-24
			bp-768:padding-b-24"
	>
		<div class="row display-block max-width-grid">
			<div
				class="padding-x-12
					bp-768:padding-x-24"
			>
				<component
					:is="injections.components.StoryContent"
					:story="props.story"
				/>
				<div
					class="border-t margin-t-24 padding-x-12 padding-y-24 border-style-dashed
						bp-768:margin-t-48 bp-768:padding-x-0"
					v-if="props.story.mediaContact"
				>
					<div class="bp-768:padding-x-24">
						<div
							class="font-weight-700 font-size-18 text-align-center margin-b-12
								bp-768:text-align-left bp-768:margin-b-24"
						>
							Media Contact
						</div>
						<component
							:contact="props.story.mediaContact"
							:is="injections.components.ContactCard"
						/>
					</div>
				</div>
				<div
					class="border-t border-style-dashed padding-y-24
						bp-768:padding-x-24"
				>
					<component
						:tags="props.story.taxonomy"
						v-if="props.story.taxonomy.length"
						:is="injections.components.Tags"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContactCard from '@/components/Common/ContactCard';
import StoryContent from '@/components/Common/StoryContent';
import Tags from '@/components/Common/Tags';


export default {
	name: 'StoryTemplate2',

	inject: {
		components: {
			default: {
				ContactCard,
				StoryContent,
				Tags,
			},
		},
	},

	props: {
		story: {
			type: Object,
			required: true,
		},
	},
};
</script>
