<template functional>
	<div
		class="page-template page-template-4"
		v-if="props.data || props.panels"
	>
		<div
			class="row display-block max-width-grid padding-x-12
				bp-1024:padding-x-24"
		>
			<!-- LAYOUT ncaa_cat2 /about/resources/research/2012-13-ncaa-national-study-substance-use-habits-college-student-athletes -->

			<div
				class="row"
				:class="{
					'margin-b-12 bp-1024:margin-b-24': props.panels.hero.length,
				}"
			>
				<div class="col-1-of-1 panel-section-hero">
					<component
						:is="injections.components.Panel"
						:panel-data="panel"
						v-for="(panel, index) in props.panels.hero"
						:key="index"
						:_class="
							index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''
						"
					/>
				</div>
			</div>

			<div class="row">
				<div class="col-1-of-1">
					<h2
						class="page-template__title margin-t-0 margin-b-12 padding-x-16 font-size-18 line-height-175
							bp-768:font-size-30 bp-768:line-height-150 bp-768:padding-x-24
							bp-1280:font-size-36"
						:class="{ 'accessible-hide': !props.data.displayTitle }"
					>
						{{ props.data.title }}
					</h2>
					<div v-html="props.data.fulltext_raw"></div>
					<component
						:is="injections.components.Panel"
						:panel-data="panel"
						v-for="(panel, index) in props.panels.main_1"
						:key="index"
						:_class="
							index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''
						"
					/>
					<component
						:is="injections.components.Panel"
						:panel-data="panel"
						v-for="(panel, index) in props.panels.main_2"
						:key="index"
						:_class="
							index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''
						"
					/>
				</div>
			</div>

			<div
				class="row"
				:class="{
					'margin-t-12 bp-1024:margin-t-24':
						props.panels.main_3.length ||
						props.panels.main_4.length,
				}"
			>
				<div class="col-1-of-1 bp-1024:col-2-of-3 panel-section-3 no-print">
					<component
						:is="injections.components.Panel"
						:panel-data="panel"
						v-for="(panel, index) in props.panels.main_3"
						:key="index"
						:_class="
							index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''
						"
					/>
				</div>
				<div class="col-1-of-1 bp-1024:col-1-of-3 panel-section-4">
					<component
						:is="injections.components.Panel"
						:panel-data="panel"
						v-for="(panel, index) in props.panels.main_4"
						:key="index"
						:_class="
							index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''
						"
					/>
				</div>
			</div>

			<div
				class="row"
				:class="{
					'margin-t-12 bp-1024:margin-t-24':
						props.panels.main_5.length ||
						props.panels.main_6.length,
				}"
			>
				<div class="col-1-of-1 bp-1024:col-1-of-3 panel-section-5">
					<component
						:is="injections.components.Panel"
						:panel-data="panel"
						v-for="(panel, index) in props.panels.main_5"
						:key="index"
						:_class="
							index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''
						"
					/>
				</div>
				<div class="col-1-of-1 bp-1024:col-2-of-3 panel-section-6">
					<component
						:is="injections.components.Panel"
						:panel-data="panel"
						v-for="(panel, index) in props.panels.main_6"
						:key="index"
						:_class="
							index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''
						"
					/>
				</div>
			</div>

			<div
				class="row"
				:class="{
					'margin-t-12 bp-1024:margin-t-24':
						props.panels.main_7.length,
				}"
			>
				<div class="col-1-of-1 panel-section-7">
					<component
						:is="injections.components.Panel"
						:panel-data="panel"
						v-for="(panel, index) in props.panels.main_7"
						:key="index"
						:_class="
							index > 0 ? 'margin-t-12 bp-1024:margin-t-24' : ''
						"
					/>
				</div>
			</div>

			<!-- END LAYOUT -->

			<div
				class="border-t border-style-dashed margin-t-12 padding-y-24
						bp-768:padding-x-24 bp-1024:margin-t-24"
				v-if="props.data.taxonomy.length"
			>
				<component
					:is="injections.components.Tags"
					:tags="props.data.taxonomy"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Tags from '@/components/Common/Tags';

import Panel from '@/components/page_panels/Panel';

export default {
	name: 'PageTemplate4',

	inject: {
		components: {
			default: {
				Tags,
				Panel,
			},
		},
	},

	props: {
		data: {
			required: true,
			type: Object,
			default: null,
		},
		panels: {
			type: Object,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
.page-template {
	&__title {
		box-shadow: inset 6px 0 0 var(--color-primary);
	}
}

[class*='panel-section-'] + [class*='panel-section-'] {
	@media (max-width: 1024px) {
		margin-top: 12px;
	}
}
</style>
